<template>
  <!-- 恒生世界 -->
  <div class="world">
    <div class="box1">
      <div class="imgae hover-deepen">
        <img
          :src="topinfo.photo && $imgurl + topinfo.photo.url"
          @click="download(topinfo.files.url)"
          alt=""
        />
      </div>
      <img class="imgae2" src="" alt="" />
      <div class="title">
        <div class="left">
          <p>{{ topinfo.yearnum }}年 {{ topinfo.subtitle }}</p>
          <p>{{ topinfo.title }}</p>
        </div>
      </div>

      <div class="neslist">
        <ul>
          <li
            v-for="(item, index) in topinfo.list"
            :key="index"
            @click="tolink(item.id)"
          >
            <p>{{ item.title }}</p>
            <p v-html="$until.removeImg(item.content)" ref="hom"></p>
          </li>
        </ul>
      </div>
      <img class="img_un" src="../../assets/img01/unimpressive.png" alt="" />
    </div>
    <!-- wow fadeInUp  data-wow-delay="0.3s" -->
    <div class="box2">
      <div class="top">
        <div class="title">历年恒生世界</div>
        <div class="cont">
          <!-- 单行展示 -->
          <div class="year-out">
            <ul class="year flex" :style="{ left: -year_l * 4.94792 + 'vw' }">
              <li
                class="cursor"
                :class="[{ on: index == year_selected }]"
                v-for="(item, index) in yearlist"
                :key="index"
                @click="chooseYear(index)"
              >
                {{ item.yearnum }}
              </li>
            </ul>
          </div>

          <div
            class="all cursor"
            :class="[{ on: -1 == year_selected }]"
            @click="chooseAll"
          >
            全部年份
          </div>
        </div>
      </div>
      <div class="bottom">
        <div
          class="image"
          v-for="(item, index) in list"
          :key="index"
          @click.stop="downlist(index)"
        >
          <img :src="item.photo && $imgurl + item.photo.url" alt="" />
          <div class="txt">{{ item.yearnum }} {{ item.subtitle }}</div>
          <div class="flexbox" v-show="curindex == index ? flag : ''">
            <p>恒生电子股份有限公司版权所有</p>
            <p>获取杂志/投稿联系：</p>
            <p>marketing@hundsun.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let WOW = require("wowjs");
export default {
  data() {
    return {
      year_selected: -1,
      year_l: 0,
      // show_all_year: false,
      //页码
      pageNo: 1,
      //页数
      pageSize: 6,
      //恒生世界数据
      topinfo: {},
      //年份
      yearlist: [],
      //对应年份数据
      list: [],
      //弹窗显示隐藏
      flag: false,
      //索引
      curindex: -1,
    };
  },
  methods: {
    chooseAll() {
      this.year_selected = -1;
      this.getdata();
    },
    chooseYear(index) {
      let year_l = this.year_l,
        len = this.yearlist.length;
      // console.log(index, len);
      if (index - year_l > 3) {
        this.year_l =
          len - year_l - 7 >= index - year_l - 3 ? index - 3 : len - 7;
      } else if (index - year_l < 3) {
        this.year_l = index > 3 ? year_l - (3 - (index - year_l)) : 0;
      }
      // console.log(this.year_l);
      this.year_selected = index;
      this.getdata();
    },
    //最近期刊数据请求
    getdata() {
      let year =
        this.yearlist[this.year_selected] &&
        this.yearlist[this.year_selected].yearnum;
      this.$fetch({
        url: "index.php/news/hssj",
        type: "post",
        data: this.$qs.stringify({
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          year,
        }),
      }).then((res) => {
        this.topinfo = res.data.topinfo;
        this.yearlist = res.data.yearlist;
        this.list = res.data.list;
        this.$nextTick(() => {
          this.getext();
          let wow = new WOW.WOW({
            boxClass: "wow",
            animateClass: "animated",
            offset: 0,
            mobile: true,
            live: true,
          }).init();
        });
      });
    },
    //最新期刊pdf下载
    download(url) {
      //       let a = document.createElement("a");
      //       let event = new MouseEvent("click");
      //       a.href = this.$imgurl+url;
      //       a.dispatchEvent(event);
      // window.open(this.$imgurl + url);
    },
    downlist(index) {
      //       console.log(url);
      //       let a = document.createElement("a");
      //       let event = new MouseEvent("click");
      //       a.href = this.$imgurl+url;
      //       a.dispatchEvent(event);
      // window.open(this.$imgurl + url);
      this.curindex = index;
      this.flag = true;
    },

    tolink(id) {
      this.$router.push({ path: "/newsview", query: { id: id} });
    },

    //文本一行省略
    getext() {
      var arr = this.$refs.hom;
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].innerText.length > 30) {     
          arr[i].innerText = arr[i].innerText.slice(0, 38) + "...";
        }
      }
    },
    //方法
    getfalse(){
      this.flag = false
    }
  },
  mounted() {
    this.getdata();
    window.addEventListener("click",this.getfalse)
  },
  beforeDestroy() {
    window.removeEventListener("click",this.getfalse)
  },
};
</script>

<style scoped>
.box1 {
  width: 1200px;
  height: 356px;
  background: #f6f7fa;
  margin: 120px auto 0;
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.img_un {
  position: absolute;
  top: -65px;
  right: 0px;
  width: 720px;
  height: 57px;
  z-index: -1;
}
.box1 .imgae {
  position: absolute;
  left: 36px;
  bottom: 36px;
  width: 350px;
  height: 398px;
  cursor: pointer;
  overflow: hidden;
}
.box1 .imgae img {
  width: 100%;
  height: 100%;
  transition: all 0.3s linear;
}
/* .box1 .imgae:hover img {
  transform: scale(1.1);
  transition: all 0.3s linear;
} */
.box1 .title {
  position: absolute;
  top: -75px;
  left: 420px;
}
.box1 .title p:nth-child(1) {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
.box1 .title p:nth-child(2) {
  font-size: 36px;
  color: #333333;
  font-weight: 600;
}
.neslist {
  width: 606px;
  margin-right: 173px;
  padding: 36px 0;
}
.neslist li {
  margin-bottom: 24px;
  cursor: pointer;
}
.neslist li p:nth-child(1) {
  font-size: 18px;
  color: #333;
  margin-bottom: 8px;
  position: relative;
  font-weight: 600;
}
.neslist li p:nth-child(1)::after {
  content: "";
  position: absolute;
  top: 10px;
  left: -25px;
  width: 6px;
  height: 6px;
  /* background: linear-gradient(315deg, #1e88f0 0%, #4a47ff 100%); */
}
.neslist li p:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  height: 20px;
  overflow: hidden;
}
.box2 {
  width: 1200px;
  margin: 40px auto;
  min-height: 500px;
}
.box2 .top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
}
.box2 .cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box2 .year-out {
  width: 725px;
  overflow: hidden;
}
.box2 .year-out .year {
  width: 1248px;
  transition: all 0.3s ease;
  position: relative;
  left: 0;
}
.box2 .year-all {
  width: 665px;
}
.box2 .year-out li,
.box2 .year-all li {
  width: 85px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 10px;
}
.box2 .year-out li {
  margin: 0;
}
.box2 .year-out li.on,
.box2 .year-all li.on {
  color: #396aff;
}
.box2 .title {
  font-size: 24px;
  color: #333333;
  font-weight: 600;
}
.box2 .all {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.box2 .all.on {
  color: #396aff;
}
.bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.bottom::after {
  content: "";
  width: 30%;
}
.bottom .image {
  width: 350px;
  height: 400px;
  margin-bottom: 60px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.bottom .flexbox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bottom .flexbox p {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  margin-top: 10px;
}

.bottom .image:hover .txt {
  background: linear-gradient(
    313deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 100%
  );
  transition: all 0.3s linear;
}
.bottom .image img {
  width: 100%;
  height: 100%;
  transition: all 0.3s linear;
}

.bottom .txt {
  width: 351px;
  height: 49px;
  background: linear-gradient(
    313deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  padding-left: 26px;
  line-height: 49px;
}
@media (min-width: 1280px) {
  .box1 {
    width: 1200px;
    height: 356px;
    background: #f6f7fa;
    margin: 120px auto 0;
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
  .img_un {
    position: absolute;
    top: -65px;
    right: 0px;
    width: 720px;
    height: 57px;
    z-index: -1;
  }
  .box1 .imgae {
    position: absolute;
    left: 36px;
    bottom: 36px;
    width: 350px;
    height: 398px;
    cursor: pointer;
    overflow: hidden;
  }
  .box1 .imgae img {
    width: 100%;
    height: 100%;
    transition: all 0.3s linear;
  }
  .box1 .title {
    position: absolute;
    top: -75px;
    left: 420px;
  }
  .box1 .title p:nth-child(1) {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }
  .box1 .title p:nth-child(2) {
    font-size: 36px;
    color: #333333;
    font-weight: 600;
  }
  .neslist {
    width: 606px;
    margin-right: 173px;
    padding: 36px 0;
  }
  .neslist li {
    margin-bottom: 24px;
    cursor: pointer;
  }
  .neslist li p:nth-child(1) {
    font-size: 18px;
    color: #333;
    margin-bottom: 8px;
    position: relative;
    font-weight: 600;
  }
  .neslist li p:nth-child(1)::after {
    content: "";
    position: absolute;
    top: 10px;
    left: -25px;
    width: 6px;
    height: 6px;
    /* background: linear-gradient(315deg, #1e88f0 0%, #4a47ff 100%); */
  }
  .neslist li p:nth-child(2) {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    height: 20px;
    /* display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden; */
    overflow: hidden;
  }
  .box2 {
    width: 1200px;
    margin: 40px auto;
    min-height: 500px;
  }
  .box2 .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
  }
  .box2 .cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .box2 .year-out {
    width: 725px;
    overflow: hidden;
  }
  .box2 .year-out .year {
    width: 1248px;
    transition: all 0.3s ease;
    position: relative;
    left: 0;
  }
  .box2 .year-all {
    width: 665px;
  }
  .box2 .year-out li,
  .box2 .year-all li {
    width: 82px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 10px;
  }
  .box2 .year-out li {
    margin: 0;
  }
  .box2 .year-out li.on,
  .box2 .year-all li.on {
    color: #396aff;
  }
  .box2 .title {
    font-size: 24px;
    color: #333333;
    font-weight: 600;
  }
  .box2 .all {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  .box2 .all.on {
    color: #396aff;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .bottom::after {
    content: "";
    width: 30%;
  }
  .bottom .image {
    width: 350px;
    height: 400px;
    margin-bottom: 60px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
  /* .bottom .image:hover img {
    transform: scale(1.1);
    transition: all 0.3s linear;
  } */
  .bottom .image img {
    width: 100%;
    height: 100%;
    transition: all 0.3s linear;
  }

  .bottom .txt {
    width: 351px;
    height: 49px;
    background: linear-gradient(
      313deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.4) 100%
    );
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    padding-left: 26px;
    line-height: 49px;
  }
}
@media (max-width: 1367px) and (min-width: 1280px) {
  .box1 {
    width: calc(1200px * 0.9);
    height: calc(356px * 0.9);
    background: #f6f7fa;
    margin: calc(120px * 0.9) auto 0;
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
  .img_un {
    position: absolute;
    top: calc(-65px * 0.9);
    right: 0px;
    width: calc(720px * 0.9);
    height: calc(57px * 0.9);
    z-index: -1;
  }
  .box1 .imgae {
    position: absolute;
    left: calc(36px * 0.9);
    bottom: calc(36px * 0.9);
    width: calc(350px * 0.9);
    height: calc(398px * 0.9);
    cursor: pointer;
    overflow: hidden;
  }
  .box1 .imgae img {
    width: 100%;
    height: 100%;
    transition: all 0.3s linear;
  }
  .box1 .title {
    position: absolute;
    top: calc(-75px * 0.9);
    left: calc(420px * 0.9);
  }
  .box1 .title p:nth-child(1) {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }
  .box1 .title p:nth-child(2) {
    font-size: 32px;
    color: #333333;
    font-weight: 600;
  }
  .neslist {
    width: calc(606px * 0.9);
    margin-right: calc(173px * 0.9);
    padding: calc(36px * 0.9) 0;
  }
  .neslist li {
    margin-bottom: calc(24px * 0.9);
    cursor: pointer;
  }
  .neslist li p:nth-child(1) {
    font-size: 18px;
    color: #333;
    margin-bottom: calc(8px * 0.9);
    position: relative;
    font-weight: 600;
  }
  .neslist li p:nth-child(1)::after {
    content: "";
    position: absolute;
    top: calc(10px * 0.9);
    left: calc(-25px * 0.9);
    width: calc(6px * 0.9);
    height: calc(6px * 0.9);
    /* background: linear-gradient(315deg, #1e88f0 0%, #4a47ff 100%); */
  }
  .neslist li p:nth-child(2) {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: calc(20px * 0.9);
    height: calc(20px * 0.9);
    /* display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden; */
    overflow: hidden;
  }
  .box2 {
    width: calc(1200px * 0.9);
    margin: calc(40px * 0.9) auto;
    min-height: calc(500px * 0.9);
  }
  .box2 .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(36px * 0.9);
  }
  .box2 .cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .box2 .year-out {
    width: calc(725px * 0.9);
    overflow: hidden;
  }
  .box2 .year-out .year {
    width: 1248px;
    transition: all 0.3s ease;
    position: relative;
    left: 0;
  }
  .box2 .year-all {
    width: calc(665px * 0.9);
  }
  .box2 .year-out li,
  .box2 .year-all li {
    width: calc(80px * 0.9 + 1px);
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    margin-bottom: calc(10px * 0.9);
  }
  .box2 .year-out li {
    margin: 0;
  }
  .box2 .year-out li.on,
  .box2 .year-all li.on {
    color: #396aff;
  }
  .box2 .title {
    font-size: 24px;
    color: #333333;
    font-weight: 600;
  }
  .box2 .all {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  .box2 .all.on {
    color: #396aff;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .bottom::after {
    content: "";
    width: 30%;
  }
  .bottom .image {
    width: calc(350px * 0.9);
    height: calc(400px * 0.9);
    margin-bottom: calc(60px * 0.9);
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
  .bottom .image:hover .txt {
    background: linear-gradient(
      313deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.8) 100%
    );
    transition: all 0.3s linear;
  }
  .bottom .image img {
    width: 100%;
    height: 100%;
    transition: all 0.3s linear;
  }

  .bottom .txt {
    width: calc(351px * 0.9);
    height: calc(49px * 0.9);
    background: linear-gradient(
      313deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.4) 100%
    );
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    padding-left: calc(26px * 0.9);
    line-height: calc(49px * 0.9);
  }
}
</style>